import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"
import yoyakuRoidLogoImage from '../images/roid1_title.png'
import kotetsuLogoImage from '../images/roid_kotetsu.png'

const Header = ({ siteTitle }) => (
  <>
  <header >
		<div id="headerbox">
      <div id="headerbox_in">
        <div id="logobox">
          <div class="item col">
            <img src={yoyakuRoidLogoImage} />
          </div>
          <div class="item col">
            <a href="https://kotetsu-japan.co.jp/"><img src={kotetsuLogoImage} /></a>
          </div>
        </div>
      </div>
		</div>
  </header>
  <div class="clear"></div>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
