/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const isRoot = (props.path === 'root');

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {!isRoot && (
        <div
          id="mainbox"
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
            <main>{props.children}</main>
            <footer>
              © {new Date().getFullYear()}, Kotetsu
            </footer>
        </div>
      )}
      {
        isRoot && (
          <main>{props.children}</main>
        )
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
